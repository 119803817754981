.paginate{
  text-align: center;
  .pagination{
    display: inline-flex;
    margin-bottom: 0;
  }
  .page-item{
    .page-link{
      color: #1d4f94;
      &:hover{
        text-decoration-line: none;
      }
      padding: 0;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 1.5;
    }
    &.active{
      .page-link{
        background-color: #1d4f94;
        border-color: #1d4f94;
        color: #fff;
      }
    }
    a:hover{
      text-decoration:none;
    }
    .page-link:focus{
      z-index:2;
      outline:0;
      box-shadow: 0 0 0 0 #fff;
    }
  }
}