.gadget{
    width: 100%;
    .indicator{
      cursor: pointer;
      float: left;
      .fa{
        width: 15px; 
        margin-right: 5px; 
        font-size: 12px;
        text-align: center; 
        padding: 1px; 
      }
    }
    .extra{
      float: left;
    }
    .gadget-container{
      padding-left: 20px;
    }
  }