@import '../../scss/variables.scss';
.scroll-bar{
  height: 100%;
  .fa-caret-up,
  .fa-caret-down{
    font-size: 12px;
    float: right;
    color: rgb(241, 243, 244);
    cursor: pointer;
    position: relative;
    z-index: 1;
    width: 15px;
    height: 15px;
    background: #fff;
    text-align: center;
    margin-left: -15px;
    &:hover{
      color: rgb(157, 180, 196);
    }
  }
  .fa-caret-down{
    margin-top: -15px;
  }

  .track-vertical{
    width: 0px !important;
    position: absolute;
    overflow: hidden;
    border-radius: 0px;
    background: #fff;
    user-select: none;
    height: calc(100% - 30px);
    padding: 0;
    top: 15px;
    right: 0px;
    &:hover{
      .thumb-vertical{
        background-color: rgb(152, 163, 166);
      }
    }
    &.show{
      width: 15px !important;
      padding: 0 5px;  
    }
  }
  .thumb-vertical{
    position: relative;
    display: block;
    width: 5px!important;
    height: auto;
    transform: translateY(0px);
    background-color: rgb(200,198,196); 
    border-radius: 5px;
    &.dragging{
      background-color: rgb(152, 163, 166);
    }
  }
}
