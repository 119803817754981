
.list {
  margin: 0px;
  overflow-y: auto;
  border-bottom: 1px solid #eee;
  margin-bottom: -1px;
  ul{
    padding-left: 0px;
    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      &:hover {
        cursor: pointer;
      }
      &.item{
        background: #fff;
      }
      &.itemSelected{
        background: lightyellow;
      }
      &.itemChecked{
        background: yellow;
      }
      &.simpler{
        &:hover {
          cursor: initial;
        }
        &.item{
          background: initial;
        }
        &.itemSelected{
          background: initial;
        }
        &.itemChecked{
          background: initial;
        }
      }
      &.simple{
        border-bottom: 0;
        &.itemSelected{
          border: 1px solid lightgoldenrodyellow;
        }
        &:hover {
          background: #ccc;
        }
      }
    }
  }
}

.list-header {
  // padding-left: 5px;
  padding-top: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  ul {
    padding-left:0px;
    margin-bottom: 0;
    .sort-cols-lbl {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        position: relative;
        bottom: 4px;
      }
    }
    .sort-cols {
      border-left: 1px solid #ddd; 
      color: rgb(102, 102, 102); 
      padding: 0px 5px;
      &:hover {
        background-color: #eee;
      }
    }
    li {
      display:inline-block;
      width: 100%;
      padding-right:5px;
      cursor: pointer;
      border: none;
      outline: none;
      &.check-all{
        input{
          cursor: pointer;
        }  
      }
      .dropdown-item{
        padding: 0 0 0 10px;
        input{
          margin-right: 10px; 
          height: 13px;
        }
      }
      .fa-ellipsis-v {
        color: #545454;
        cursor: pointer;
      }
      .fa-download {
        color: #545454;
        cursor: pointer;
        &.disabled {
          cursor: default;
          color: #787878;
        }
      }
      .fa-file-excel-o {
        color: #545454;
        cursor: pointer;
        &.disabled {
          cursor: default;
          color: #787878;
        }
      }
    }
   
  }
}
.list-item{
  display: inline-flex;
  width: 100%;
  .item-checkbox {
    flex: 0 0 16px;
    width: 16px;
    text-align: center;
    padding-top: 5px;
  }
  .item{
    width: 100%;
    flex: 1 1 auto;
  }
  .item-with-checkbox{
    width: calc(100% - 16px);
    flex: 1 1 auto;
  }
}