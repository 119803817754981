body{
  // overflow: hidden;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style:-ms-autohiding-scrollbar;
}
input{
  height: 25px;
  &::-webkit-input-placeholder {
    font-style: italic;
  }
  &:-moz-placeholder {
    font-style: italic;  
  }
  &::-moz-placeholder {
    font-style: italic;  
  }
  &:-ms-input-placeholder {  
    font-style: italic; 
  }
  &::-ms-clear {
    display: none;
  }
}

.row{
  margin-right: 0px;
  margin-left: 0px;
  width: 100%;
}
.fa-plus, .fa-minus, .fa-folder-open{
  cursor: pointer;
  color: gray;
  margin-top: 5px;
}

.clickable-div{
  color: #2b5581;
  cursor: pointer;
  height: 21px;
  padding: 0 2px;
  &:hover{
    border-bottom: 1px solid #2b5581;
  }
}

.flat-button{
  color: #2b5581;
  background-color: transparent;
  border: 0;
  height: 24px;
  font-weight: 600;
  width:fit-content;
  &:hover{
    border-bottom: 1px solid #2b5581;
  }
  &:disabled{
    color: #767676;
    &:hover{
      border-bottom: 0;
    }
  }
  &.behind{
    margin-left: 15px;
  }
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}
.sr-only{
  margin-top: 1px;
}
button:disabled {
  background-color: #fff;
  color: rgb(89,89,89);
  border: 1px solid #eee;
}