@import '../../scss/variables.scss';

.link {
  display: inline-block;
  a {
    background-color: transparent; 
    border: 0; 
    color: $link-color;
    padding: 1px 1px 0 1px; 
    text-align: left !important;
    text-decoration: underline;
    cursor: pointer;
    &.disabled{
      color: #666666;
      text-decoration: none;
    }
  }
}

.title {
  abbr {
    color: #dc0000;
    text-decoration: none !important;
  }
}

