.date-picker{
  .date-picker-input{
    width: 105px; 
    &.invalid{
      color: red;
    }
  }
  .fa-calendar{
    margin-left: 3px;
  }
  .DayPicker{
    position: absolute;
    left: 0;
    top: 50px;
    background: #fff;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    .DayPicker-Day{
      &:focus{
        outline-style: solid !important;
        outline-color: rgb(77,144,254) !important;
        outline-width: 1.2px !important;
      }
    }
    .DayPicker-Weekday{
      color: #000;
    }
  }
}