.tabs{
	ul{
    padding-left: 5px;
    &.nav-tabs{
      border-bottom: 1px solid #eee;
    }
		li{
			z-index: 1;
			&:hover{
				cursor: pointer;
      }
      background-color: #f8f8f8;
      border: 1px solid #eee;
      margin-bottom: -1px;
      border-left: 0;
      &:first-child{
        border-left: 1px solid #eee;
      }
      &.active{
        border-bottom: 1px solid #fff;
        font-weight: bold;
        text-decoration: underline;
        background-color: #fff;
      }
		}
	}
}