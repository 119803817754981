.animate-input {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
  
    input {
        border: none;
        outline: none;
        border-bottom: 2px solid #EFF3F7;
        width: 100%;

        &:focus ~ .underline-animation {
            left: 0;
        }
    }

    .underline-animation {
        transition: all 0.5s;
        display: inline-block;
        bottom: 0;
        left: -100%;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #2a56c6;  
    }
}