@import '../../scss/variables.scss';

.e-modal{
  display: flex;
}

.e-modal-hide{
  display: none;
}

.e-modal-background{
  background: #ffffff;
  color: #666666;
  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.e-modal-dialog{
  z-index: $z-index-modal-dialog;
  width: 50%;
  padding: 10px;
  height: 300px;
  position: fixed;
  top: 25%;
  left: 25%;
  background: #fff;
  box-shadow: 0 0 10px black;
  .last-focus{ 
    margin-left: -10px; 
    opacity: 0; 
    position: absolute; 
    cursor: initial;
  }
}

.e-modal-header{
  cursor: move;
  border-bottom: 1px solid #eee;
}

.e-modal-close{
  position: absolute;
  top: 5px;
  right: 10px;
  color: #2b5581;
  background-color: inherit;
  border: 0;
  height: 24px;
  padding-top: 0px;
}
.e-modal-footer{
  border-top: 1px solid #eee;
  padding: 5px 0;
  margin-top: 10px;
}