$window-min-width: 580px;

//-----z-index---------------------------------
$z-index-message: 7000;
$z-index-spinner: 5000;
$z-index-modal: 5000;
$z-index-modal-dialog: 6000;
//---------------------------------------------

$link-color: rgb(8, 83, 148);
$base-color: rgb(8, 83, 148);