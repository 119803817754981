
.custom-dropdown2{
    cursor: pointer;
    display: inline-block;
    border: none;
    outline: none;
    // width: 100%;
    .dropdown{
      &.scrollable{
        display: flex;
      }
      .dropdown-display {
        width: 100%;
        display: flex;
        text-align: left;
      }
    }
    .dropdown-menu{
      top: auto;
      flex-direction: column;
      &.show{
        display: flex;
      }
      &.pull-up{
        bottom: 100%;
        top: auto;
      }
    }
  
    &.pull-right{
      text-align: right;
      .dropdown-menu{
        left: auto;
        right: 0;
      }
    }
  
    &.fixed{
      .dropdown-menu{
        position: fixed;
        left: auto;
      }
    }
  }