

.deletable {
    display: inline;
    .item{
      padding: 2px 2px 2px 5px;
      margin: 2px 0px 2px 2px;
      display: table-cell;
      background: #f5f5f5;
      display: inline-block;
      position: relative;
      text-align: left;
      margin-right: 2px;
      margin-top: 1px;
      padding: 1px 5px 1px 5px;
      border-radius: 3.01px;
      border: 1px solid #ccc;
      // border: 1px solid rgb(102,102,102);
    }
  }