@import '../../scss/variables.scss';

.tabs {
  width: 100%;
}
.tabs2{
  border: 1px solid rgb(204, 204, 204);
  .item{
    cursor: pointer;
    display: inline-block;
    padding: 0 5px;
    &:not(:last-child){
      border-right: 1px solid rgb(204, 204, 204);
    }
    background-color: rgb(221, 221, 221);
    &.active{
      color: $base-color;
      background-color: #fff;
    }
  }
}

.tabs2-link{
  .item {
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    &.active{
      color: $base-color;
      border-bottom: 2px solid $base-color;
      font-weight: 600;
    }
  }
}

.tabs2-header{
  .item {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid rgb(245, 245, 245);
    padding: 5px 0;
    color: $base-color;
    text-decoration: underline;
    &.active{
      background-color: rgb(245, 245, 245);
      border-bottom: 2px solid rgb(26, 26, 26);
      font-weight: 600;
      color: rgb(26, 26, 26);
      text-decoration: none;
    }
    &.inactive{
      text-decoration: none;
      cursor: auto;
      color: rgb(26, 26, 26);
    }
  }
}


.evista-header {
  .item {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    padding: 3px 10px;
    color: $base-color;

    &.active{
      border-left: solid 1px #FFFFFF;
      border-top: solid 1px #1f61b5;
      border-right: solid 1px #1f61b5;
      background-color: #FFFFFF;
      color: #a8552c;
      font-weight: bold;
      text-decoration: none;
    }
    &.inactive{
      border-left: solid 1px #d9e6f5;
      border-right: solid 1px #a3c2e4;
      border-top: solid 1px #a3c2e4;
      border-bottom: solid 1px #1f61b5;
      background-color: #bcd8f7;
      text-decoration: none;
      color: rgb(26, 26, 26);

      &:hover {
        text-decoration: underline;
      }
    }
    
  }
}

