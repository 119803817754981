
.confirm{
  .sub-text{
    padding: 20px 5px 30px 10px;
  }
  .footer{
    border-top: 1px solid #eee;
    text-align: left;
    padding: 10px;
    button{
      width: 80px;
      margin: 0 10px;
    }
  }
  .btn-primary {
    background-color: #0c55a6 !important;
    padding: 2px 5px;
}
}