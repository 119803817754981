.tree-node {
    .tree-header{
        width: 100%;
        background: transparent;
        display: flex;
        &.tree-item {
            padding: 2px;
            display: flex;
        }
        .headerTitle {
            padding: 2px 2px 2px 5px;
        }
        .collapsed{
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAA3NCSVQICAjb4U/gAAAAJFBMVEX///9wcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHDRPAkXAAAADHRSTlMAESIziJmqu8zd7v+91kxoAAAACXBIWXMAAAsSAAALEgHS3X78AAAAFnRFWHRDcmVhdGlvbiBUaW1lADIxLzEyLzEymvNa/wAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAArSURBVAiZY2DAB1iaoAy2XQZQVvRiKIMVLlQ9AU0EpoZjhwLUnEa81iABAFHzB8GYPzdNAAAAAElFTkSuQmCC);
            background-repeat: no-repeat;
            padding-left: 20px;
            background-position: center center;
            cursor: pointer;
        }
        .expanded{
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAA3NCSVQICAjb4U/gAAAAJFBMVEX///9wcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHDRPAkXAAAADHRSTlMAESIziJmqu8zd7v+91kxoAAAACXBIWXMAAAsSAAALEgHS3X78AAAAFnRFWHRDcmVhdGlvbiBUaW1lADIxLzEyLzEymvNa/wAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAySURBVAiZY2AgCbA0MDCkgBgcWxlYd4AYjN0B0YvAclrbdxmAGcyrF0OVWxqQZjwDAwA8XgfBciyedgAAAABJRU5ErkJggg==);
        }
    }
    .children {
        width: 100%;
    }
}

.tree {
    ul {
        &.tree-item-parent {
            list-style: none;
            margin: 0;
            padding-left: 20px;
        }
        .tree-item {
            cursor: pointer;
            display: inline-block;
            width: 100%;
            // background-color: #fff;
            vertical-align: top;
            &.selected {
                background-color: lightgrey;
            }
        }
    }

}