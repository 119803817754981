.landing{
    .row {
      margin-right: 0px;
      margin-left: 0px;
      width: 100%;
      padding: 10px 0px;
    }
    .auth{
      padding: 30px 0px 30px 0px;
      background-color: #F7F8F9;
      display: flex;
      height: auto;
      justify-content: center;
      .show-area{
        position: absolute;
        top: 10%;
        display: flex;
        padding: 0;
        max-height: 90%;
        overflow-y: auto;
      }
    }
    .banner-logo {
      max-height: 36px;        
      vertical-align: middle;
      border: 0;
    }
    .largeIcon {
      height: 48px;
      vertical-align: middle;
      float: left;
    }
  
    .show-box{
      padding: 18px;
      border: 1px solid #dedede;
      width: 450px;
      height: 100%;
      .header{
        display: none;
      }
    }
    .largeTextNoWrap {
      height: 48px;
      display: table-cell;
      vertical-align: middle;
      white-space: nowrap;
      font-size: 1.2em;
    }
    .indentNonCollapsible {
      padding-left: 16px;
      &.common{
        width:calc(100% - 84px)
      }
    }
    .go{
      cursor: pointer;
      .fa-angle-down{
        margin: 5px;
        background-color: #fff;
      }
      &:hover{
          background-color: #eee;
          .fa-angle-down{
            background-color: #eee;
          }
      }
      .img{
          height: 48px;
      }
      .fa-arrow-circle-right{
        color: #333;
        font-size: 28px;
        height: 32px;
        margin-left: 5px;
        cursor: pointer;
        &:hover{
          color: rgb(0, 115, 197);
        }
      }  
    }
    .drop-down-tenants{
      width: 100%;
      border: 1px solid #ccc;
      text-align: right;
      .dropdown{
        width: 100%;
      }
      .dropdown-menu{
        max-height: 300px; 
        width: 100%; 
        top: 100%;
      }
      .display{
        width: 100%;
        text-align: left;
        display: flex;
        .label{
          width: calc(100% - 21px);
        }      
      }
    }
  }
  